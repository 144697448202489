<template>
  <div class="activity">
    <Header />
    <HeaderBottomOrders />
    <div class="investment-activity pb-2">
      <b-container class="activity-container">
        <div class="activity-title mb-4">
          <b-row class="justify-content-center">
            <h1>Saldo RDN</h1>
          </b-row>
        </div>
      </b-container>
    </div>
    <div class="list-activity">
      <b-container size="sm" class="list-activity-container">
        <b-skeleton-wrapper :loading="false">
          <!-- <template #loading>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
          </template>
          <div v-if="user.institutional != null"></div>
          <div v-else>
            <template v-if="!orders.length">
              <div class="check-transaction mt-5">
                <b-row class="justify-content-center text-center">
                  <b-col class="my-auto">
                    <b-button to="/reksadana" class="btn-buy px-5 py-3">
                      Mulai Investasi
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </div>
          <b-card
            class="list-activity-card mt-4"
            v-for="(value, index) in ordersLoaded"
            :key="index"
          >
            <div class="row">
              <div class="col-lg-6">
                <p class="transaction-date">
                  {{ moment(value.updated_at).format(" DD MMM YYYY") }}
                </p>
              </div>
              <div class="col-lg-6">
                <div v-if="value.id_transaction_type === 1">
                  <div class="row">
                    <template v-if="value.promo_transaction != null">
                      <div class="col-lg-3">
                        <p class="transaction-type text-center">Pembelian</p>
                      </div>

                      <div class="col-lg-3">
                        <p class="transaction-promo text-center">
                          {{ value.promo_transaction.promo_code }}
                        </p>
                      </div>

                      <div class="col-lg-6">
                        <p class="transaction-status text-center">
                          {{
                              value.status === "waiting itrade"
                              ? "Menunggu Verifikasi"
                              : value.status === "waiting confirm"
                              ? "Menunggu Verifikasi"
                              : value.status == "waiting sales"
                              ? "Menunggu Verifikasi Sales"
                              : value.status == "approve"
                              ? "Dalam Proses"
                              : "Menunggu Pembayaran"
                          }}
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-lg-6">
                        <p class="transaction-type text-center text-lg-right">
                          Pembelian
                        </p>
                      </div>
                      <div class="col-lg-6">
                        <p class="transaction-status text-center">
                          {{
                          value.status === "waiting itrade"
                            ? "Menunggu Verifikasi"
                            : value.status === "waiting confirm"
                              ? "Menunggu Verifikasi"
                              : value.status == "waiting sales"
                              ? "Menunggu Verifikasi Sales"
                              : value.status == "approve"
                              ? "Dalam Proses"
                              : "Menunggu Pembayaran"
                          }}
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-else-if="value.id_transaction_type === 2">
                  <div class="row">
                    <div class="col-lg-6">
                      <p class="transaction-type text-center text-lg-right">
                        Penjualan
                      </p>
                    </div>
                    <div class="col-lg-6">
                      <p class="transaction-status text-center">
                        {{
                          value.status === "waiting itrade"
                            ? "Menunggu Verifikasi"
                            : value.status === "waiting confirm"
                              ? "Menunggu Verifikasi"
                            : value.status == "waiting sales"
                            ? "Menunggu Verifikasi Sales"
                            : value.status == "approve"
                            ? "Dalam Proses"
                            : "null"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-lg-6">
                      <p class="transaction-type text-center text-lg-right">
                        Pengalihan
                      </p>
                    </div>
                    <div class="col-lg-6">
                      <p class="transaction-status text-center">
                        {{
                          value.status === "waiting itrade"
                            ? "Menunggu Verifikasi"
                            : value.status === "waiting confirm"
                              ? "Menunggu Verifikasi"
                            : value.status == "waiting sales"
                            ? "Menunggu Verifikasi Sales"
                            : value.status == "approve"
                            ? "Dalam Proses"
                            : "null"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-row>
              <div class="col-lg-4 col-md-6 my-auto">
                <div class="fund-name">
                  <b-row>
                    <div class="col-lg-12 col-md-6">
                      <div
                        v-if="value.id_transaction_type == 1"
                        class="my-auto"
                      >
                        <div class="row">
                          <div class="col-lg-12 col-md-6">
                            <div class="row">
                              <div class="col-lg-2 col-md-6 mb-2">
                                <div v-if="value.product.image == null">
                                  <img
                                    :src="
                                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product.fund_name
                                    "
                                  />
                                </div>
                                <div v-else>
                                  <img
                                    :src="`${imageCore}/images/products/${value.product.image}`"
                                    alt="Fund Image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-10 col-md-6">
                                <div v-if="value.status != 'waiting transfer'">
                                  <b-button
                                    class="btn-modal-portfolio"
                                    :to="
                                      '/order/subscription-status/' +
                                      value.code +
                                      '/' +
                                      value.fund_code
                                    "
                                  >
                                    <p class="text-left mb-2">
                                      Reksa Dana
                                      {{
                                        value.product
                                          ? value.product.fund_type
                                          : ""
                                      }}
                                    </p>
                                    <h4>
                                      {{
                                        value.product
                                          ? value.product.fund_name
                                          : ""
                                      }}
                                    </h4>
                                  </b-button>
                                </div>
                                <div v-else>
                                  <b-button
                                    class="btn-modal-portfolio"
                                    :to="
                                      '/order/subscription-checkout/' +
                                      value.code +
                                      '/' +
                                      value.fund_code
                                    "
                                  >
                                    <p class="text-left mb-2">
                                      Reksa Dana
                                      {{
                                        value.product
                                          ? value.product.fund_type
                                          : ""
                                      }}
                                    </p>
                                    <h4>
                                      {{
                                        value.product
                                          ? value.product.fund_name
                                          : ""
                                      }}
                                    </h4>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="value.id_transaction_type == 2"
                        class="my-auto"
                      >
                        <div class="row">
                          <div class="col-lg-12 col-md-6">
                            <div class="row">
                              <div class="col-lg-2 col-md-6 mb-2">
                                <div v-if="value.product.image == null">
                                  <img
                                    :src="
                                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product.fund_name
                                    "
                                  />
                                </div>
                                <div v-else>
                                  <img
                                    :src="`${imageCore}/images/products/${value.product.image}`"
                                    alt="Fund Image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-10 col-md-6">
                                <b-button
                                  class="btn-modal-portfolio"
                                  :to="
                                    '/order/redeem-status/' +
                                    value.code +
                                    '/' +
                                    value.fund_code
                                  "
                                >
                                  <p class="text-left mb-2">
                                    Reksa Dana
                                    {{
                                      value.product
                                        ? value.product.fund_type
                                        : ""
                                    }}
                                  </p>
                                  <h4>
                                    {{
                                      value.product
                                        ? value.product.fund_name
                                        : ""
                                    }}
                                  </h4>
                                </b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-lg-12 col-md-6">
                            <div class="row">
                              <div class="col-lg-2 col-md-6 mb-2">
                                <div v-if="value.product_out.image == null">
                                  <img
                                    :src="
                                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product_out.fund_name
                                    "
                                  />
                                </div>
                                <div v-else>
                                  <img
                                    :src="`${imageCore}/${value.product_out.image}`"
                                    alt="Fund Image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-10 col-md-6">
                                <div class="switch-from">
                                  <p>
                                    Dari Reksa Dana
                                    {{
                                      value.product_out
                                        ? value.product_out.fund_type
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <b-button
                                  class="btn-modal-portfolio"
                                  :to="
                                    '/order/switching-status/' +
                                    value.code +
                                    '/' +
                                    value.product_out.fund_code
                                  "
                                >
                                  <h4 class="text-left">
                                    {{
                                      value.product_out
                                        ? value.product_out.fund_name
                                        : ""
                                    }}
                                  </h4>
                                </b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-6">
                            <div class="row">
                              <div class="col-lg-2 col-md-6 mb-2">
                                <div v-if="value.product_in.image == null">
                                  <img
                                    :src="
                                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                      value.product_in.fund_name
                                    "
                                  />
                                </div>
                                <div v-else>
                                  <img
                                    :src="`${imageCore}/${value.product_in.image}`"
                                    alt="Fund Image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-10 col-md-6">
                                <div class="switch-to">
                                  <p>
                                    Ke Reksa Dana
                                    {{
                                      value.product_in
                                        ? value.product_in.fund_type
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <b-button
                                  class="btn-modal-portfolio"
                                  :to="
                                    '/order/switching-status/' +
                                    value.code +
                                    '/' +
                                    value.product_out.fund_code
                                  "
                                >
                                  <h4>
                                    {{
                                      value.product_in
                                        ? value.product_in.fund_name
                                        : ""
                                    }}
                                  </h4>
                                </b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 my-auto">
                <div class="investment-value">
                  <div v-if="value.id_transaction_type == 1">
                    <p>Nilai Investasi</p>
                    <h3 class="mt-n2">
                      {{ value.product ? value.product.fund_ccy : ""
                      }}{{ formatPrice(value.amount_nominal) }}
                    </h3>
                  </div>
                  <div v-else-if="value.id_transaction_type == 2">
                    <p>{{ formatNab(value.amount_unit) }} unit</p>
                    <h3 class="mt-n2">
                      {{ value.product ? value.product.fund_ccy : "" }}
                      {{
                        formatPrice(
                          value.amount_nominal != null
                            ? value.amount_nominal
                            : value.amount_unit * value.nav_per_unit
                        )
                      }}
                    </h3>
                  </div>
                  <div v-else>
                    <p>Nilai Investasi</p>
                    <h3 class="mt-n2">
                      {{ value.product_out ? value.product_out.fund_ccy : ""
                      }}{{
                        formatPrice(
                          value.switch_out_nominal != null
                            ? value.switch_out_nominal
                            : value.switch_out_unit * value.nav_per_unit
                        )
                      }}
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 my-auto"></div>
              <div class="col-lg-4 col-md-6 text-right my-auto">
                <div class="timer-buy">
                  <b-row class="justify-content-center justify-content-lg-end">
                    <div v-if="value.status == 'waiting transfer'">
                      <div v-if="value.id_transaction_type == 1">
                        <div
                          class="
                            col-lg-12 col-md-6
                            my-auto
                            text-left text-lg-right
                          "
                        >
                          <b-button
                            :to="
                              '/order/subscription-checkout/' +
                              value.code +
                              '/' +
                              value.fund_code
                            "
                            class="btn-buy py-2 px-4"
                          >
                            Bayar
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        value.status == 'waiting confirm' ||
                        value.status == 'approve'
                      "
                    >
                      <div
                        class="
                          col-lg-12 col-md-6
                          my-auto
                          text-left text-lg-right
                        "
                      >
                        <div class="estimation text-left">
                          <p class="ml-0">Estimasi Selesai</p>
                          <h3 class="mt-n2 text-left">
                            3 hari bursa <br />
                            <small>setelah tanggal transaksi</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
            </b-row>
          </b-card> -->
          <div class="contentCustom">
            <div v-if="active" class="back">
              <p @click="handleBack">Kembali</p>
            </div>
            <div class="body">
              <!-- <p class="heading">
                {{ active ? "Tarik Dana" : "Saldo RDN" }}
              </p> -->

              <div v-if="!active" id="heading" class="jarak">
                <p class="heading">Saldo Efektif</p>
                <div class="saldo">
                  <p class="format">Rp</p>
                  <p v-if="!loading" class="money">
                    {{ balance | formatCurrency }}
                  </p>
                  <p v-else class="money">- -</p>
                </div>
                <b-button
                  :disabled="loading"
                  @click="$router.push('withdraw/history')"
                  >History</b-button
                >
              </div>

              <!-- Template -->
              <div v-if="!active" class="contantInformation jarak">
                <!-- <div class="item">
                    <h1>Informasi Saldo Anda:</h1>
                    <div class="content">
                      <div class="information">
                        <p>Reksadana</p>
                        <p>IDR 8.184.430</p>
                      </div>
                      <div class="divider"></div>
                      <div class="information">
                        <p>SBN</p>
                        <p>IDR 9.184.430</p>
                      </div>
                      <div class="divider"></div>
                      <div class="information">
                        <p>Obligasi</p>
                        <p>IDR 100.000.000</p>
                      </div>
                      <div class="divider"></div>
                    </div>
                  </div> -->
                <div class="item" v-if="error">
                  <h1>{{ error }}</h1>
                </div>
                <div class="item" v-else-if="loading">
                  <div class="d-flex justify-content-center">
                    <b-spinner
                      style="width: 50px; height: 50px"
                      size="lg"
                    ></b-spinner>
                  </div>
                </div>
                <div class="item" v-else>
                  <h1>Informasi Rekening:</h1>
                  <div class="content">
                    <div class="information">
                      <p>Bank Penerbit</p>
                      <p>{{ selectedBank?.banks?.bank_name || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nama Pemilik Akun</p>
                      <p>{{ selectedBank?.account_name || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nomor Rekening Pribadi</p>
                      <p>{{ selectedBank?.account_number || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                  </div>
                </div>
              </div>
              <div v-if="active" class="contantInformation jarak">
                <!-- <div class="item">
                  <h1>Penarikan dari RDN:</h1>
                  <div class="content">
                    <div class="information">
                      <p>Bank Sinarmas</p>
                      <p>001892922</p>
                    </div>
                  </div>
                </div> -->
                <div class="item">
                  <h1>Transfer Ke Rekening:</h1>
                  <div class="content">
                    <div class="information">
                      <p>{{ selectedBank?.banks?.bank_name || "-" }}</p>
                      <p>{{ selectedBank?.account_number || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nama Pemilik Akun</p>
                      <p>{{ selectedBank?.account_name || "-" }}</p>
                    </div>

                    <p class="inform">
                      Permintaan penarikan dana diatas pukul 10.00 WIB akan
                      diproses di hari kerja bursa berikutnya
                    </p>
                  </div>
                </div>
              </div>

              <div class="input jarak" role="group" v-if="!!active">
                <b-col cols="10" role="group">
                  <b-input
                    id="nominalInputForm"
                    :formatter="formatter"
                    :state="formStateNominal === null ? null : false"
                    type="text"
                    v-model="nominal"
                    class="mr-2"
                  ></b-input>
                  <b-form-invalid-feedback id="hargaPembelianInput">{{
                    formStateTextNominal
                  }}</b-form-invalid-feedback>
                </b-col>
                <b-form-invalid-feedback id="nominalInputForm">{{
                  formStateTextNominal
                }}</b-form-invalid-feedback>
                <!-- <p class="mt-1 inform">Minimal Penarikan Rp100.000</p> -->
              </div>

              <b-button
                v-if="!active"
                :disabled="loading || !selectedBank"
                @click="active = 'confirm'"
                block
                class="buttonTarik jarak"
              >
                Tarik Dana
              </b-button>
              <b-button
                v-else
                :disabled="loading || (active && formStateNominal !== null)"
                @click="tarikDana()"
                block
                class="buttonTarik jarak"
              >
                {{ loading ? "..." : "Tarik Dana" }}
              </b-button>
            </div>
          </div>
        </b-skeleton-wrapper>
      </b-container>
    </div>
    <Footer3 />
    <ModalMessage
      :open="!!openModalMessage"
      :closeHandler="closeModalMessage"
      :title="openModalTitle"
      :bodyText="openModalMessage || ''"
      :buttons="modalButton"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomOrders from "@/components/partials/header/HeaderBottomOrders.vue";
import Footer3 from "@/components/partials/footer/Footer3.vue";
import moment from "moment";
// import numeral from "numeral";
import ModalMessage from "@/components/partials/modal/message.vue";
import { encryptData, backFormatter, formatter  } from "@/utils";
const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "withdraw",
  metaInfo: {
    title: "Withdraw | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomOrders,
    Footer3,
    ModalMessage,
  },
  data() {
    return {
      active: "",
      balance: "-",
      s21ClientData: null,

      // Logic Input
      nominal: "0",
      loading: false,
      error: null,

      // Modal
      openModalMessage: false,
      openModalTitle: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
    }),
    selectedBank() {
      // Jika tidak ada akun, maka kembalikan null
      if (
        !Array.isArray(this.user?.individual?.accounts) ||
        !this.s21ClientData ||
        !this.s21ClientData?.PrivateBankAccountNo
      ) {
        return null;
      }

      const found = this.user.individual.accounts.find((candidate) => {
        return (
          candidate.account_number == this.s21ClientData?.PrivateBankAccountNo
        );
        // // Select Bank Default
        // return candidate.default
      });

      if (found) {
        return found;
      }

      return null;
      // return this.user.individual.accounts[0]
    },
    formStateBackNominal() {
      return this.backFormatter(this.nominal);
    },
    formStateNominal() {
      if (isNaN(this.formStateBackNominal)) return "NOT_EXIST";
      else if (this.formStateBackNominal < 100000) return "NOT_ACHIVE_MINIMAL";
      else if (this.formStateBackNominal > this.backFormatter(this.balance))
        return "NOT_ACHIVE_MINIMAL_2";
      else return null;
    },
    formStateTextNominal() {
      switch (this.formStateNominal) {
        case "NOT_ACHIVE_MINIMAL": {
          return `Minimal Penarikan adalah 100.000`;
        }
        case "NOT_ACHIVE_MINIMAL_2": {
          return `Melebihi batas. Saldo anda kurang mencukupi`;
        }
        case "NOT_EXIST": {
          return "Mohon masukan angka";
        }
        default: {
          return null;
        }
      }
    },
    modalButton() {
      return [
        {
          text: "Lihat Transaksi",
          handler: () => {
            this.$router.push("/withdraw/history");
          },
          solid: true,
        },
        {
          text: "Tutup",
          handler: () => {
            this.closeModalMessage();
          },
        },
      ];
    },
  },
  async mounted() {
    try {
      // if (!this.selectedBank || this.user.individual?.is_fbonds !== 1) {
      if (this.user.individual?.is_fbonds !== 1) {
        this.$router.push("/portfolio");
      }

      this.loading = true;
      const clientId = this.user.individual.client_id;

      if (!clientId) {
        alert("Error In App");
        console.error("Client ID Not Found");
        return;
      }

      let response = await instance({
        method: "GET",
        url: `${this.app_url}balance/position?type=2&client_id=${clientId}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      let response2 = await instance.get(
        `s21client?clientId=${encryptData(clientId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const respond = response2?.data?.data;
      if (!respond) {
        throw new Error("Struktur Respond Data Tidak Sesuai");
      }

      this.s21ClientData = respond;
      // console.log(response)
      this.balance = this.backTransaformFormatter(response.data.data.amount);
      // this.balance = this.backTransaformFormatter('76389758.191');
      this.loading = false;
    } catch (err) {
      alert("Error");
      console.log(err);
      this.error = "Error when fetching Data";
    }
  },
  methods: {
    async tarikDana() {
      try {
        if (!this.loading) {
          this.loading = true;

          await instance({
            method: "POST",
            url: `${this.app_url}fbonds/cash-withdrawals`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `${this.access_token}`,
            },
            data: {
              date: moment().format("YYYY-MM-DD"),
              client_id: this.user.individual.client_id,
              // "client_id_2": this.user.individual.client_id,
              amount: `${this.formStateBackNominal}`,
              description: "Cashout",
              bank_account_no: this.selectedBank.account_number,
              //"transaction_id": "ABC155"
            },
          });

          this.openModalTitle = "Berhasil";
          this.openModalMessage = "Berhasil melakukan withdraw";
        }
      } catch (e) {
        console.log(e);
        this.openModalTitle = "Terjadi Kesalahan";
        this.openModalMessage = "Terjadi kesalahan saat melakukan withdraw";
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      if (this.loading || this.error) return;

      if (this.active) {
        this.active = "";
      } else {
        this.$router.push("/portfolio");
      }
    },
    /*
    formatter(val) {
      if (!val) return;
      const candidate = val.toString().split('');
      if (candidate[candidate.length - 1] === ',') return val;
      return numeral(val).format(candidate.find(candidate => candidate === ',') ? '0,0.0[000]' : '0,0')
    },
    */
    formatter,
    backFormatter,
    // backFormatter(val) {
    //   return parseFloat(val.split('').filter(candidate => candidate !== ".").map(candidate => candidate.replace(',', '.')).join(''));
    // },
    closeModalMessage() {
      this.openModalMessage = false;
    },
    backTransaformFormatter(val) {
      if (!val) return;
      return val
        .toString()
        .split("")
        .map((val) => val.replace(".", ","))
        .join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.contentCustom {
  margin-top: 30px;
  width: 500px;
  margin: 30px auto auto;

  .back {
    p {
      color: #6b7280;
      /* Text Desktop Base/Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }

  .body {
    padding: 35px;
    background-color: white;

    #heading {
      display: grid;
      justify-items: center;
      gap: 20px;

      .saldo {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        overflow: auto;

        .format {
          color: #9ca3af;
          font-family: Inter;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin: 0;
        }

        .money {
          color: #111827;
          font-family: Inter;
          font-size: 45px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          margin: 0;
        }
      }

      .heading {
        color: var(--gray-900, #111827);
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      button {
        width: 100%;
        background-color: #b4c9ff;
        padding: 12px 16px;
        border-radius: 6px;
        color: #0b318f;
        text-align: center;
        /* Text L/Bold */
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
      }
    }

    .heading {
      color: #111827;
      text-align: center;
      /* Text Desktop LG/Bold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .contantInformation {
      display: grid;
      gap: 44px;

      .input {
        p.inform {
          color: #6b7280;
          /* Text M/Regular */
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .item {
        display: grid;
        gap: 10px;

        h1 {
          color: #1f2937;
          /* Text L/Bold */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }

        .content {
          display: grid;
          gap: 8px;

          .information {
            display: flex;
            justify-content: space-between;

            p:first-child {
              color: #374151;
              /* Text Desktop Base/Regular */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }

            p:last-child {
              color: #111827;
              text-align: right;
              /* Text Desktop Base/Bold */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
            }
          }

          .inform {
            color: #374151;
            /* Text Desktop Base/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: #e5e7eb;
          }
        }
      }
    }

    .buttonTarik {
      background: #ff6975;
      padding: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      transition: 0.3s;
    }

    .buttonTarik:active {
      opacity: 0.5;
    }

    .buttonTarik:hover {
      opacity: 0.8;
    }

    .buttonTarik:focus {
      opacity: 0.6;
    }

    .jarak {
      margin-top: 59px;
    }
  }
}

.activity {
  background: #f3f4f6;
}

.investment-activity {
  background: white;
}

.activity-container {
  padding-top: 120px;
  margin-bottom: 0px;
}

.list-activity-card {
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.15);
  border-radius: 6px;
}

.list-activity {
  background: #f3f4f6;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.activity-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.transaction-date {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-type {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-promo {
  font-weight: 700;
  font-size: 12px;
  color: #2c5282;
  background: #ebf8ff;
  border-radius: 6px;
}

.transaction-status {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.fund-padding {
  margin-left: 20px;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    color: #1f2937;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #6b7280;
  }
}

.investment-value {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.purchased {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.estimation {
  p {
    font-weight: 400;
    font-size: 14px;
    color: #6b7280 !important;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    color: #111827;
  }
}

.on-proccess {
  border-bottom: 3px solid $tertiaryColor;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.finish {
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.timer-buy {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.switch-from {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.switch-to {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.btn-buy {
  font-family: $inter;
  background: $secondaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: white;
}

.btn-buy:hover {
  background: $hoverSecondary;
}

.btn-buy:focus {
  box-shadow: none !important;
}

.btn-buy:active {
  background: $hoverSecondary !important;
  outline: none !important;
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  h4 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
}
</style>
